import paymentSettings from '../../../settingsParams';

export type PaymentTermsAndConditionsPageInfo = {
  id?: string;
  title?: string;
  pageUriSEO?: string;
};

export const getPaymentTermsAndConditionsPageInfo = (settings: {
  get(key: unknown & (typeof paymentSettings)['termsPageInfo']): any;
}): PaymentTermsAndConditionsPageInfo => {
  return settings.get(paymentSettings.termsPageInfo) || {};
};
