import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { GROUPS_APP_ID } from '../../editor/app-config';
import { ISocialGroupsContext } from './interfaces';

export const socialGroupsPropsMap = async function (
  flowAPI: ControllerFlowAPI,
): Promise<ISocialGroupsContext> {
  const isInstalled =
    await flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
      sectionId: 'group',
      appDefinitionId: GROUPS_APP_ID,
    });

  const groupPageUrl =
    await flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
      sectionId: 'group',
      appDefinitionId: GROUPS_APP_ID,
    });

  return {
    groupPageUrl,
    isGroupsInstalled: isInstalled,
    socialGroup: null,
  };
};
