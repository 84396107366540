import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  addOnLoginHandler,
  IOnLoginHandlerPriority,
} from '../../GeneralDataProvider/helpers/onLogin';
import { userProviderPropsMap } from '../userProviderPropsMap';
import {
  getParticipantPageDeepLink,
  isParticipantPageAvailable,
  navigateToProgramPage,
} from '../../Location/helpers/generateLinksToProgramForList';
import { locationProviderPropsMap } from '../../Location/locationProviderPropsMap';
import { isUserJoinedAlready } from './userTypeHandlers';
import { getChallengeSlugFromLocation } from '../../Location/helpers/getChallengeSlugFromLocation';

export default function handleUserAfterLogin() {
  addOnLoginHandler({
    priority: IOnLoginHandlerPriority.FIRST,
    handler: async (flowAPI: ControllerFlowAPI) => {
      const userProvider = await userProviderPropsMap(flowAPI);
      const location = locationProviderPropsMap(flowAPI);
      const user = await userProvider.updateParticipant();

      await userProvider.updateParticipantLockedState();

      if (await isParticipantPageAvailable(flowAPI)) {
        const isViewer = flowAPI.environment.isViewer;
        const challengeId = await getChallengeSlugFromLocation(flowAPI);
        const isChallengePage = location?.path?.includes('challenge-page');

        if (
          isViewer &&
          challengeId &&
          isChallengePage &&
          isUserJoinedAlready(user.userType)
        ) {
          flowAPI.controllerConfig.setProps({
            isForcedBlankPage: true,
          });
          await navigateToProgramPage(flowAPI, {
            challengeId,
            isParticipant: true,
            deepLink: getParticipantPageDeepLink(flowAPI),
          });
        }
      }
    },
  });
}
