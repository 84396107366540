import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';

export function renderSeoMetatagsPage(
  flowAPI: ControllerFlowAPI,
  challenge: Challenge,
  itemType: string = 'CHALLENGES_PAGE',
) {
  const { isViewer } = flowAPI.environment;

  if (!isViewer) {
    return;
  }

  const { wixCodeApi } = flowAPI.controllerConfig;
  const seoAPI = wixCodeApi.seo;

  if (!challenge?.settings) {
    seoAPI.renderSEOTags({
      itemType,
      itemData: null,
      seoData: null,
    });

    return;
  }

  const itemData = {
    program: challenge,
  };

  seoAPI.renderSEOTags({
    itemType,
    itemData,
    seoData: challenge?.settings?.seo?.seoData,
  });
}
