import { isV3Participant } from './index';
import { Participant, ParticipantV1 } from '../../types/v3Types';

export function incrementProgress(participant: Participant, diff: number = 1) {
  const participantV1 = participant as ParticipantV1;

  if (!participant) {
    return;
  }
  if (isV3Participant(participant)) {
    throw new Error('Not implemented');
  }

  if (participantV1?.stepsSummary) {
    participantV1.stepsSummary.completedStepsNumber =
      participantV1.stepsSummary.completedStepsNumber + diff;
  }

  return participantV1;
}
