import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getMockedChallenge } from '../../../main/getMockedChallenges';
import { request } from '../../../../services/request';
import { querySteps } from '@wix/ambassador-online-programs-v3-step/http';
import type { Step } from '../../../../types/v3Types';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';

export default async function requestChallengeSteps(
  programId: string,
  flowAPI: ControllerFlowAPI,
  limit?: number,
): Promise<Step[]> {
  if (limit === 0) {
    return [];
  }
  const mockedChallenge = getMockedChallenge(programId, flowAPI);
  if (!programId) {
    return null;
  } else if (mockedChallenge) {
    return [];
  }

  try {
    return (
      await request({
        flowAPI,
        options: querySteps({
          programId,
          query: {
            filter: {
              programId,
            },
          },
        }),
        errorOptions: {
          errorCodesMap: {
            applicationError: {},
          },
        },
      })
    )?.data?.steps;
  } catch (error) {
    const resolvedError = flowAPI.errorHandler.getResolvedError(error);
    handleError({
      error,
      resolvedError,
      context: 'requestChallengeSteps',
      preventErrorMonitorReport: false,
    });
    return [];
  }
}
