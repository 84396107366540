import { getChallengeEligiblePaidPlans } from '../../../components/ChallengesPage/Widget/components/Pricing/helpers/getChallengeEligiblePaidPlans';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeId } from '../../storage-contexts/Challenge/helpers/getChallengeId';
import { getPaidPlansData } from '../../storage-contexts/PaidPlans';

/**
 * we need to abort join flow after login in case when user already has eligible paid plan
 */
export async function isNeedToAbortJoinFlowAfterLogin(
  flowAPI: ControllerFlowAPI,
): Promise<boolean> {
  const challengeId = await getChallengeId(flowAPI);
  const paidPlans = await getPaidPlansData(flowAPI);

  const challengeEligiblePaidPlan = getChallengeEligiblePaidPlans(
    paidPlans.eligiblePlans,
    paidPlans.userPaidPlans,
    challengeId,
  );

  return Boolean(challengeEligiblePaidPlan.length);
}
