import { queryParticipants } from '@wix/ambassador-online-programs-v3-participant/http';
import { QueryParticipantsRequest } from '@wix/ambassador-online-programs-v3-participant/types';

import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { request } from '../../../../services/request';
import { getUserFromConfig } from '../../../User/helpers/userContextHelpers';

export async function requestParticipantV3(
  query: QueryParticipantsRequest['query'],
  flowAPI: ControllerFlowAPI,
) {
  return (
    await request({
      flowAPI,
      options: queryParticipants({
        query,
      }),
      errorOptions: {
        errorCodesMap: {
          applicationError: {},
        },
      },
    })
  )?.data?.participants;
}

export async function requestParticipantV3ForChallenge(
  challengeId: string,
  flowAPI: ControllerFlowAPI,
) {
  const { id } = getUserFromConfig(flowAPI);
  const participants = await requestParticipantV3(
    { filter: { memberId: id, programId: challengeId } },
    flowAPI,
  );
  return participants?.[0];
}
