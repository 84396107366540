import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isProgramHasOneTimePayment } from '../../../selectors/payment/oneTimePayment';
import { isUserJoinedAlreadyWithoutSuspended } from '../../../contexts/User/helpers/userTypeHandlers';
import { isPrivateChallenge } from '../../../selectors/isPrivateChallenge';

export function needToRedirectBackToVisitorPage({
  initialProps,
  flowAPI,
}: {
  initialProps: any;
  flowAPI: ControllerFlowAPI;
}) {
  const participant = initialProps?.participant;
  const challenge = initialProps?.challengeData?.challenge;

  if (
    !isProgramHasOneTimePayment(challenge) ||
    isUserJoinedAlreadyWithoutSuspended(participant) ||
    (isPrivateChallenge(challenge) && !participant)
  ) {
    return true;
  }

  return (
    !participant &&
    !flowAPI.experiments.enabled('spec.programs.CreateParticipantOnPaymentPage')
  );
}
