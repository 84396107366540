import { navigateToPaidPlans } from '../../../services/navigateToPaidPlans';
import { Pages } from '../../Location/LocationContext';
import { SelectedPaymentOption } from '../../../components/ChallengesPage/Widget/components/Pricing/interfaces';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  getChallengePaidPlans,
  getChallengeVisiblePaidPlans,
} from '@wix/challenges-web-library';
import { locationProviderPropsMap } from '../../Location/locationProviderPropsMap';
import { ActionTypes } from '../../main/biInterfaces';
import { getPaymentType } from './userContextHelpers';
import { IUserProviderProps } from '../UserProvider';
import { isUserJoinedAlreadyWithoutSuspended } from './userTypeHandlers';
import { getChallengeEligiblePaidPlans } from '../../../components/ChallengesPage/Widget/components/Pricing/helpers/getChallengeEligiblePaidPlans';
import { errorHandlerPropsMap } from '../../ErrorHandler/errorHandlerPropsMap';
import { biBeforeAndAfter } from '../../../services/biHelpers';
import { createPaymentOrder } from '@wix/ambassador-challenges-v1-participant/http';
import { request } from '../../../services/request';
import { generateQueryString } from '../../Location/urlHelpers';
import { getChallengeId } from '../../storage-contexts/Challenge/helpers/getChallengeId';
import { getPaidPlansInitialData } from '../../storage-contexts/PaidPlans';
import { PARTICIPANT_PAGE_ID } from '../../../editor/app-config';
import { getChallengeSlugFromLocation } from '../../Location/helpers/getChallengeSlugFromLocation';
import { userProviderPropsMap } from '../userProviderPropsMap';

export async function createOrder({
  flowAPI,
  startDate,
  paymentOption,
  timeZone,
}: {
  flowAPI: ControllerFlowAPI;
  startDate?: string;
  paymentOption: 'SinglePayment' | 'PaidPlans';
  timeZone: string;
}) {
  const userProvider = await userProviderPropsMap(flowAPI);
  const challengeId = await getChallengeId(flowAPI);
  const payment = await biBeforeAndAfter(
    flowAPI.bi,
    ActionTypes.CREATE_PAYMENT_ORDER_ID,
    async (actionId) => {
      return (
        await request({
          flowAPI,
          options: createPaymentOrder({
            participantId: userProvider.participant.id,
            challengeId,
            startDate,
            paymentType: getPaymentType(paymentOption),
            timeZone,
            actionId,
          }),
          errorOptions: {
            errorCodesMap: {
              applicationError: {},
            },
          },
        })
      )?.data;
    },
  );

  const participant = payment.participant;

  await userProvider.updateParticipant(participant);
  return payment;
}

export async function goToPaymentPage({
  flowAPI,
  showOneAppInfo,
  orderId,
}: {
  flowAPI: ControllerFlowAPI;
  showOneAppInfo?: boolean;
  orderId?: string;
}) {
  const location = locationProviderPropsMap(flowAPI);
  location.goToPage({
    pageId: Pages.Payment,
    challengeId: `${await getChallengeSlugFromLocation(
      flowAPI,
    )}?${generateQueryString({
      ...(orderId ? { orderId } : {}),
      showOneAppInfo: !!showOneAppInfo,
    })}`,
  });
}

export async function payForChallenge(
  flowAPI: ControllerFlowAPI,
  userProvider: IUserProviderProps,
  paymentOption: SelectedPaymentOption,
  startDate: string,
  timeZone: string,
  settings?: { showOneAppInfo?: boolean },
): Promise<{ userJoined: boolean }> {
  const error = await errorHandlerPropsMap(flowAPI);
  const challengeId = await getChallengeId(flowAPI);

  if (!paymentOption) {
    console.error('No payment options. Cant create a payment request');
    error.showError('toast.error.contact-site-owner');
    return Promise.resolve({ userJoined: false });
  }

  const payment = await createOrder({
    flowAPI,
    startDate,
    paymentOption,
    timeZone,
  });

  if (
    paymentOption === 'PaidPlans' &&
    isUserJoinedAlreadyWithoutSuspended(
      userProvider.participant?.transitions?.[0]?.state,
    )
  ) {
    return { userJoined: true };
  }

  if (paymentOption === 'SinglePayment') {
    await goToPaymentPage({
      flowAPI,
      showOneAppInfo: settings?.showOneAppInfo,
      orderId: payment?.orderId,
    });

    return { userJoined: false };
  }

  if (paymentOption === 'PaidPlans') {
    const t = flowAPI.translations.t;
    const paidPlans = await getPaidPlansInitialData(flowAPI);

    const challengeVisiblePaidPlans = getChallengeVisiblePaidPlans(
      challengeId,
      paidPlans.userPaidPlans,
    );
    const challengePaidPlans = getChallengePaidPlans(
      challengeId,
      paidPlans.userPaidPlans,
    );
    const challengeEligiblePaidPlans = getChallengeEligiblePaidPlans(
      paidPlans.eligiblePlans,
      paidPlans.userPaidPlans,
      challengeId,
    );

    const programSlug = await getChallengeSlugFromLocation(flowAPI);
    await navigateToPaidPlans({
      pageId: PARTICIPANT_PAGE_ID,
      url: programSlug,
      planIds: (challengeVisiblePaidPlans.length // we should send visible paid plans, or if this is only hidden, send one of them
        ? challengeVisiblePaidPlans.filter((plan) => {
            return !challengeEligiblePaidPlans.find(
              (elPlan) => elPlan.id === plan.id,
            );
          })
        : challengePaidPlans.slice(0, 1)
      ).map((paidPlan) => paidPlan.id),
      titleText: t(
        'pricing.payment-option.subscription.paid-plan.thank-you.title',
      ),
      buttonText: t(
        'pricing.payment-option.subscription.paid-plan.thank-you.button-title',
      ),
      contentText: t(
        'pricing.payment-option.subscription.paid-plan.thank-you.content',
      ),
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
    });

    return { userJoined: false };
  }
}
