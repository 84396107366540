import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getPaymentTermsAndConditionsPageInfo } from '../Settings/Tabs/Display/getPaymentTermsAndConditionsPageInfo';
import { getLocationFromFlowAPI } from '../../../contexts/Location/locationProviderPropsMap';

export const getTermsAndConditionsUrl = async (flowAPI: ControllerFlowAPI) => {
  const pageInfo = getPaymentTermsAndConditionsPageInfo(flowAPI.settings);
  if (!pageInfo.id) {
    return '';
  }
  const siteStructure =
    await flowAPI.controllerConfig.wixCodeApi.site.getSiteStructure();
  const location = getLocationFromFlowAPI(flowAPI);
  const url =
    siteStructure.pages.find((p) => p?.name === pageInfo?.title)?.url ||
    pageInfo.pageUriSEO;

  return { termsAndConditionsUrl: location.baseUrl + url };
};
