import { isEmpty } from 'lodash';
import { encodeBase64 } from './encode-base64';
import { OP_APP_DEF_ID } from '../appDefId';

export const PAID_PLANS_APP_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';

export function getPaidPlansSectionUrl(wixCodeApi) {
  try {
    const sectionUrl = wixCodeApi.site.getSectionUrl({
      sectionId: 'membership_plan_picker_tpa',
      appDefinitionId: PAID_PLANS_APP_ID,
    });
    return sectionUrl.relativeUrl;
  } catch (e) {
    console.info('[Online Programs]Paid plans page is not installed');
    return '';
  }
}

export const navigateToPaidPlans = ({
  pageId,
  planIds,
  url,
  titleText,
  buttonText,
  contentText,
  wixCodeApi,
}: {
  pageId: string;
  url: string;
  planIds: string[];
  titleText: string;
  buttonText: string;
  contentText: string;
  wixCodeApi: any;
}) => {
  console.info('navigateToPaidPlans', { pageId, url });
  const queryParams: any = {
    planIds: planIds.join(','),
    navigateToSectionProps: encodeBase64({
      sectionId: pageId,
      appDefinitionId: OP_APP_DEF_ID,
      state: url,
      shouldRefreshIframe: true,
    }),
  };
  const content: any = { titleText, buttonText, contentText };

  try {
    queryParams.verticalStatusContent = encodeBase64(content);
  } catch (error) {
    console.error(error);
  }

  let path = '';
  if (!isEmpty(queryParams)) {
    path = `?appSectionParams=${encodeURIComponent(
      JSON.stringify(queryParams),
    )}`;
  }

  const sectionUrl = getPaidPlansSectionUrl(wixCodeApi);
  console.info('navigateToPaidPlans', { sectionUrl, path });
  return wixCodeApi.location.to(`${sectionUrl}${path}`);
};
