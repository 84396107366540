import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isPaidPlansInstalled } from './isPaidPlansInstalled';
import { getPaidPlansData, resetPaidPlansCache } from '../data';
import {
  addOnLoginHandler,
  IOnLoginHandlerPriority,
} from '../../../GeneralDataProvider/helpers/onLogin';

export default function handlePaidPlansAfterLogin() {
  addOnLoginHandler({
    priority: IOnLoginHandlerPriority.SECONDARY,
    handler: async (flowAPI: ControllerFlowAPI) => {
      const isPPInstalled = await isPaidPlansInstalled(flowAPI);

      if (isPPInstalled) {
        resetPaidPlansCache();
        // userProviderPropsMap?.cache?.clear()
        flowAPI.controllerConfig.setProps({
          ...(await getPaidPlansData(flowAPI)),
        });
      }
    },
  });
}
