import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IUserContext } from '../UserContext';
import { leaveProgram } from './leaveProgram';
import {
  isParticipantPageAvailable,
  navigateToProgramPage,
} from '../../Location/helpers/generateLinksToProgramForList';
import { getPaidPlansInitialData } from '../../storage-contexts/PaidPlans';

export async function leaveTheChallenge(
  flowAPI: ControllerFlowAPI,
  participantId: string,
  userProvider: IUserContext,
  challengeId: string,
): Promise<void> {
  await leaveProgram(flowAPI, participantId, challengeId);

  if (await isParticipantPageAvailable(flowAPI)) {
    await navigateToProgramPage(flowAPI, {
      challengeId,
      isParticipant: false,
    });
  } else {
    await userProvider.updateParticipant();
    // because of PPs doesn't load when member is joined we need to trigger load of PPs on leave challenge
    const pps = await getPaidPlansInitialData(flowAPI);

    flowAPI.controllerConfig.setProps({ ...pps, participantSteps: [] });
  }
}
