import { resolveProgramId } from './resolveProgramId';
import { requestChallengeSections, requestChallengeSteps } from './api';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Section, Step } from '../../../types/v3Types';
import { getSectionsWithSteps } from '../../../selectors/getSectionsWithSteps';
import { IChallengeContext } from './interfaces';

export const preloadProgramStepData = async (
  flowAPI: ControllerFlowAPI,
  preloadStepLimit: number,
): Promise<Pick<IChallengeContext, 'challengeSteps' | 'challengeSections'>> => {
  const { programId } = await resolveProgramId(flowAPI);
  const [preloadedSections, preloadedSteps] = await Promise.all([
    requestChallengeSections(programId, flowAPI, preloadStepLimit),
    requestChallengeSteps(programId, flowAPI, preloadStepLimit),
  ]);
  const { challengeSections, challengeSteps } = combineSectionsWithSteps({
    flowAPI,
    sections: preloadedSections,
    steps: preloadedSteps,
  });

  return {
    challengeSections,
    challengeSteps,
  };
};

interface CombineSectionsWithStepsParams {
  flowAPI: ControllerFlowAPI;
  sections: Section[];
  steps: Step[];
}

function combineSectionsWithSteps({
  sections,
  steps,
}: CombineSectionsWithStepsParams) {
  const v3StepAndSections = getSectionsWithSteps(sections, steps);
  return {
    challengeSections: v3StepAndSections?.sections,
    challengeSteps: v3StepAndSections?.steps,
  };
}
