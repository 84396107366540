import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isLoggedInUser } from '../../../contexts/User/helpers/userContextHelpers';
import { hasCoupons } from '@wix/ambassador-challenges-v1-challenge/http';
import { request } from '../../../services/request';

export const coupons = async (flowAPI: ControllerFlowAPI) => {
  if (!isLoggedInUser(flowAPI)) {
    return { hasCouponsForPrograms: false };
  }
  try {
    const resp = await request({
      flowAPI,
      options: hasCoupons({}),
      ignoreError: true, // coupons are not essential for the payment flow
      errorOptions: {
        errorCodesMap: {
          applicationError: {},
        },
      },
    });
    return {
      hasCouponsForPrograms: resp.data.result,
    };
  } catch (e) {
    return { hasCouponsForPrograms: false };
  }
};
