import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { SelectedPaymentOption } from '../../../components/ChallengesPage/Widget/components/Pricing/interfaces';
import { isNeedToAbortJoinFlowAfterLogin } from './isNeedToAbortJoinFlowAfterLogin';
import { createParticipant } from './createParticipant';
import {
  Participant,
  State as ParticipantState,
} from '@wix/ambassador-challenges-v1-participant/types';
import { goToPaymentPage, payForChallenge } from './payForChallenge';
import {
  getTimeZone,
  getUserType,
  isLoggedInUser,
  navigateToThankYouPage,
  promptLogin,
  toServerStartDate,
} from './userContextHelpers';
import { isUserJoinedAlreadyWithoutSuspended } from './userTypeHandlers';
import { IUserProviderProps } from '../UserProvider';
import { loadingPropsMap } from '../../Loading/loadingPropsMap';
import {
  clearProvidersCache,
  initChallengePage,
} from '../../../components/ChallengesPage/controller';
import { biChangePage } from '../../../services/biHelpers';
import { ScreenNames } from '../../main/biInterfaces';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { ILoadingStatus } from '../../Loading/interfaces';
import {
  isParticipantPageAvailable,
  navigateToProgramPage,
} from '../../Location/helpers/generateLinksToProgramForList';

import { getChallengeSlugFromLocation } from '../../Location/helpers/getChallengeSlugFromLocation';
import { getParticipantState } from '../../../selectors/participant/getState';
import { isPrivateChallenge } from '../../../selectors/isPrivateChallenge';
import { getChallengeData } from '../../storage-contexts/Challenge';

export enum IJoinResult {
  'OK' = 'OK',
  'NOT_ELIGIBLE' = 'NOT_ELIGIBLE',
  'ERROR' = 'ERROR',
}

export async function joinToChallenge(
  flowAPI: ControllerFlowAPI,
  userProvider: IUserProviderProps,
  selectedPaymentOption: SelectedPaymentOption,
  startDate?: string,
  settings?: { showOneAppInfo?: boolean },
): Promise<IJoinResult> {
  const startDateFormatted = toServerStartDate(startDate);
  const timeZone = getTimeZone(flowAPI);
  const loadingProviderProps = loadingPropsMap(flowAPI);
  const challengeData = await getChallengeData(flowAPI);

  if (
    selectedPaymentOption === 'SinglePayment' &&
    !isPrivateChallenge(challengeData.challenge) &&
    flowAPI.experiments.enabled('spec.programs.CreateParticipantOnPaymentPage')
  ) {
    await goToPaymentPage({
      flowAPI,
      showOneAppInfo: settings?.showOneAppInfo,
    });
    return;
  }

  loadingProviderProps.showLoader();

  if (!isLoggedInUser(flowAPI)) {
    try {
      await promptLogin(flowAPI);
      await userProvider.updateParticipant();
    } catch (e) {
      loadingProviderProps.hideLoader();
      return IJoinResult.ERROR;
    }

    if (await isNeedToAbortJoinFlowAfterLogin(flowAPI)) {
      loadingProviderProps.hideLoader();
      return IJoinResult.OK;
    }
  }

  const currentParticipantUserType = getUserType(
    { loggedIn: true },
    userProvider.participant,
  );

  if (isUserJoinedAlreadyWithoutSuspended(currentParticipantUserType)) {
    if (await isParticipantPageAvailable(flowAPI)) {
      const challengeId = await getChallengeSlugFromLocation(flowAPI);
      await navigateToProgramPage(flowAPI, {
        challengeId,
        isParticipant: true,
      });
    } else {
      loadingProviderProps.hideLoader();
    }

    return IJoinResult.OK;
  }

  let participant: Participant = null;
  try {
    participant = await createParticipant({
      timeZone,
      userProvider,
      startDateFormatted,
      flowAPI,
    });
  } catch (error) {
    const resolvedError = flowAPI.errorHandler.getResolvedError(error);

    if (
      error?.response?.data?.details?.applicationError?.code ===
      'join_not_eligible'
    ) {
      loadingProviderProps.hideLoader(ILoadingStatus.NOT_ELIGIBLE);
      return IJoinResult.NOT_ELIGIBLE;
    } else {
      loadingProviderProps.hideLoader();
      handleError({
        resolvedError,
        error,
        context: 'createParticipant',
      });
    }
  }

  if (participant) {
    switch (getParticipantState(participant)) {
      case ParticipantState.PAYMENT_REQUESTED:
      case ParticipantState.PAYMENT_STARTED:
      case ParticipantState.SUSPENDED:
        const { userJoined } = await payForChallenge(
          flowAPI,
          userProvider,
          selectedPaymentOption,
          startDateFormatted,
          timeZone,
          settings,
        );

        return userJoined
          ? ((await navigateToThankYouPage(flowAPI)) as any)
          : IJoinResult.OK;
      case ParticipantState.JOINED:
      case ParticipantState.RUNNING:
        if (await isParticipantPageAvailable(flowAPI)) {
          const challengeId = await getChallengeSlugFromLocation(flowAPI);
          await navigateToProgramPage(flowAPI, {
            isParticipant: true,
            challengeId,
          });

          return IJoinResult.OK;
        } else {
          void clearProvidersCache();
          await initChallengePage(flowAPI); //  the way to update the page w/o reload
          await biChangePage(
            flowAPI.bi,
            ScreenNames.ChallengePageForParticipant,
          );
          loadingProviderProps.hideLoader();

          return IJoinResult.OK;
        }
      case ParticipantState.JOIN_REQUESTED:
        /*
          todo: looks like we dont need to refresh tha page for "join requested" – remove the code below
          void clearProvidersCache();
          await initChallengePage(flowAPI); //  the way to update the page w/o reload
          await biChangePage(flowAPI.bi, ScreenNames.ChallengePageForParticipant);
         */
        loadingProviderProps.hideLoader();

        return IJoinResult.OK;
      default:
        flowAPI?.errorMonitor?.captureMessage(
          `[challenge] Unexpected participant state "${participant?.transitions[0].state}" during join.`,
        );
        loadingProviderProps.hideLoader();
        return IJoinResult.OK;
    }
  }

  loadingProviderProps.hideLoader();
  console.error('[CHALLENGE] Unable to join user');

  return IJoinResult.ERROR;
}
