import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getTimeZone } from '../../../contexts/User/helpers/userContextHelpers';
import { userProviderPropsMap } from '../../../contexts/User/userProviderPropsMap';
import { createParticipant } from '../../../contexts/User/helpers/createParticipant';
import { createOrder } from '../../../contexts/User/helpers/payForChallenge';
import { coupons } from './coupons';

export async function prepareUserForPayment(flowAPI: ControllerFlowAPI) {
  const timeZone = getTimeZone(flowAPI);
  const userProvider = await userProviderPropsMap(flowAPI);
  await createParticipant({
    timeZone,
    flowAPI,
    userProvider,
  });
  const { participant } = await createOrder({
    flowAPI,
    paymentOption: 'SinglePayment',
    timeZone,
  });
  return {
    participant,
    ...(await coupons(flowAPI)),
  };
}
